import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0];

export const dictionary = {
		"/(protected)": [~5,[2]],
		"/(protected)/activities": [6,[2]],
		"/(protected)/categories": [7,[2]],
		"/(protected)/categories/create": [9,[2]],
		"/(protected)/categories/[id]": [8,[2]],
		"/(protected)/groups": [10,[2]],
		"/(protected)/groups/create": [12,[2]],
		"/(protected)/groups/[id]": [11,[2]],
		"/(protected)/internalItems": [13,[2]],
		"/(protected)/internalItems/items/[id]/locations": [14,[2]],
		"/(protected)/internalItems/locations/[id]/items": [15,[2]],
		"/(protected)/items": [16,[2]],
		"/(protected)/items/create": [19,[2]],
		"/(protected)/items/[id]": [17,[2]],
		"/(protected)/items/[id]/locations": [18,[2]],
		"/(protected)/locations": [20,[2]],
		"/(protected)/locations/create": [24,[2]],
		"/(protected)/locations/[id]": [21,[2]],
		"/(protected)/locations/[id]/items": [22,[2]],
		"/(protected)/locations/[id]/items/[item_ids]/orders": [23,[2]],
		"/(public)/login": [55,[4]],
		"/(protected)/orders": [25,[2]],
		"/(protected)/orders/create": [27,[2]],
		"/(protected)/orders/[id]": [26,[2]],
		"/(protected)/purchases": [28,[2]],
		"/(protected)/purchases/create": [30,[2]],
		"/(protected)/purchases/import": [31,[2,3]],
		"/(protected)/purchases/import/1": [32,[2,3]],
		"/(protected)/purchases/import/2": [33,[2,3]],
		"/(protected)/purchases/import/3": [34,[2,3]],
		"/(protected)/purchases/import/finish": [35,[2]],
		"/(protected)/purchases/items": [36,[2]],
		"/(protected)/purchases/items/[id]": [37,[2]],
		"/(protected)/purchases/[id]": [29,[2]],
		"/(protected)/roles": [38,[2]],
		"/(protected)/roles/create": [40,[2]],
		"/(protected)/roles/[id]": [39,[2]],
		"/(protected)/settings": [41,[2]],
		"/(protected)/suppliers": [42,[2]],
		"/(protected)/suppliers/create": [49,[2]],
		"/(protected)/suppliers/[id]": [43,[2]],
		"/(protected)/suppliers/[id]/items": [44,[2]],
		"/(protected)/suppliers/[id]/items/create": [46,[2]],
		"/(protected)/suppliers/[id]/items/[item_id]": [45,[2]],
		"/(protected)/suppliers/[id]/purchaseFiles/create": [48,[2]],
		"/(protected)/suppliers/[id]/purchaseFiles/[id]": [47,[2]],
		"/(protected)/system": [50,[2]],
		"/(protected)/users": [52,[2]],
		"/(protected)/users/create": [54,[2]],
		"/(protected)/users/[id]": [53,[2]],
		"/(protected)/user": [51,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';