import { dev } from '$app/environment';
import { user } from '$lib/user';
import * as Sentry from '@sentry/svelte';

if (!dev) {
	Sentry.init({
		dsn: 'https://a7aeaf302410a2ee2bd7151ba60661e4@o4507763891699712.ingest.de.sentry.io/4507800203558992',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

user.subscribe((user) => Sentry.setContext('user', user));
